import * as styles from '../styles/regulations.module.scss';

import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import SectionTitle from '../components/SectionTitle';
import Seo from '../components/SEO';

import statutoFile from '../content/STATUTO.pdf';

const Regulations = ({ data }) => {
  const { frontmatter: regulations } = data.regulations.nodes.find(
    ({ frontmatter }) => frontmatter.slug === 'internal-regulation'
  );

  const { frontmatter: rulesOfConduct } = data.regulations.nodes.find(
    ({ frontmatter }) => frontmatter.slug === 'rules-of-conduct'
  );

  return (
    <Layout>
      <Seo title={'Pilart - Regolamento'} description={'Regolamento'} />
      <SectionTitle title="STATUTO" />
      <div className={styles.regulationsWrapper}>
        <section>
          <a className={styles.statutoLink} href={statutoFile} download="STATUTO.pdf">
            CLICCA QUI PER SCARICARE LO STATUTO DELLA SOCIETÀ SPORTIVA DILETTANTISTICA
          </a>
        </section>
        <SectionTitle title="REGOLAMENTO" />
        <section className={styles.regulationsSection}>
          <div>
            <h2>{regulations.title}</h2>
            {regulations.subtitle && <p>{regulations.subtitle}</p>}
            <ul className={styles.regulationsList}>
              {regulations.rules.map((rule) => (
                <li>{rule}</li>
              ))}
            </ul>
          </div>
          <div>
            <h2>{rulesOfConduct.title}</h2>
            {rulesOfConduct.subtitle && <p>{rulesOfConduct.subtitle}</p>}
            <ul className={styles.rulesOfConduct}>
              {rulesOfConduct.rules.map((rule) => (
                <li>{rule}</li>
              ))}
            </ul>
          </div>
        </section>
      </div>
      <ScrollToTopButton />
    </Layout>
  );
};

export default Regulations;

export const query = graphql`
  query RegulationsPage {
    regulations: allMarkdownRemark(filter: { frontmatter: { type: { eq: "regulations" } } }) {
      nodes {
        frontmatter {
          type
          title
          subtitle
          slug
          rules
        }
        id
      }
    }
  }
`;
